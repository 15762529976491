<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="configName"
      :value.sync="baseFormModel.configName"
      :placeholder="$t('lang.gles.workflow.pleaseInputConfigName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t("lang.gles.common.addNew") }}
        </el-button>
        <!-- <el-button
          type="primary"
          icon="el-icon-circle-check"
          :disabled="!selectValue.length"
          @click="batchChangeStatus(0)"
        >
          {{ $t("lang.gles.common.enable") }}
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-circle-close"
          :disabled="!selectValue.length"
          @click="batchChangeStatus(1)"
        >
          {{ $t("lang.gles.common.disable") }}
        </el-button> -->
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        ref="mTable"
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @copyItem="copyItem"
        @pageChange="pageChange"
        @selection-change="(val) => (selectValue = val)"
      >
        <template #enableStatus="{ row }">
          <el-switch
            :value="row.status"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            @click.native="changeStatus(row)"
          />
        </template>
      </m-table>
    </el-card>
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import tallyConfigMixins from '@/mixins/tallyConfigMixins'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'TallyConfigList',
  components: {
    ColumnConfig,
    QueryView,
    MTable
  },
  mixins: [listMixins, tallyConfigMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        configName: ''
      },
      formModel: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      selectValue: []
    }
  },
  computed: {
    ...mapState('base', ['statusFlagList', 'containerDirectionList']),
    ...mapState('materialManage', ['materialClassifyFirstList']),
    ...mapState('strategyManage', ['robotTypeList', 'robotModelList']),
    moreQueryData: function() {
      return getMoreQueryFormData(this, { ...this.moreQueryFormModel })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      const options = {
        materialClassifyFirstList: this.materialClassifyFirstList
      }
      return getSearchTableItem(this, options, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      // checkBox: true,
      operateWidth: '160px',
      operate: [
        {
          event: 'editItem',
          label: this.$t('lang.gles.common.edit')
        },
        {
          event: 'copyItem',
          label: this.$t('lang.gles.common.copy')
        },
        {
          event: 'delItem',
          confirm: true,
          confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
          label: this.$t('lang.gles.common.delete'),
          customClass: 'danger'
        }
      ]
    }
    this.doSearch(this.baseFormModel)
    this.initSelectList()
  },
  methods: {
    ...mapActions('materialManage', ['getClassifyFirst']),
    ...mapActions('strategyManage', ['getRobotTypeList', 'getRobotModelList']),
    // 查询
    async doSearch(params, api = 'queryTallyLogisticsConfig') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.workflow(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 新增
    updateCom() {
      const options = {
        turnoverContainerTypeList: this.turnoverContainerTypeList,
        moveContainerTypeList: this.moveContainerTypeList,
        materialClassifyFirstList: this.materialClassifyFirstList,
        warehouseAreaList: this.warehouseAreaList,
        robotTypeList: this.robotTypeList,
        robotModelList: this.robotModelList
      }

      this.$emit('updateCom', {
        currentCom: 'tallyConfigAdd',
        mode: 'add',
        options
      })
    },
    // 复制
    copyItem({ row }) {
      const options = {
        turnoverContainerTypeList: this.turnoverContainerTypeList,
        moveContainerTypeList: this.moveContainerTypeList,
        materialClassifyFirstList: this.materialClassifyFirstList,
        warehouseAreaList: this.warehouseAreaList,
        robotTypeList: this.robotTypeList,
        robotModelList: this.robotModelList
      }
      this.$emit('updateCom', {
        currentCom: 'tallyConfigAdd',
        mode: 'copy',
        row,
        options
      })
    },
    editItem({ row }) {
      if (row.status === 0) {
        this.$message.error(this.$t('lang.gles.workflow.checkMsg01'))
        return
      }
      const options = {
        turnoverContainerTypeList: this.turnoverContainerTypeList,
        moveContainerTypeList: this.moveContainerTypeList,
        materialClassifyFirstList: this.materialClassifyFirstList,
        warehouseAreaList: this.warehouseAreaList,
        robotTypeList: this.robotTypeList,
        robotModelList: this.robotModelList
      }
      this.$emit('updateCom', {
        currentCom: 'tallyConfigAdd',
        mode: 'edit',
        row,
        options
      })
    },
    // 启用/禁用
    async batchChangeStatus(status) {
      const selectData = this.selectValue
      const datas = selectData.map((item) => {
        return { id: item.id, status }
      })
      const params = {
        data: datas
      }
      const { code } = await this.$httpService(
        this.$apiStore.workflow('enableOrDisableTallyLogisticsConfig'),
        params
      )
      if (code) return
      if (status === 1) {
        this.$message.success(this.$t('lang.gles.strategy.disableSuccess'))
      } else {
        this.$message.success(this.$t('lang.gles.strategy.enableSuccess'))
      }
      selectData.forEach(cv => {
        cv.status = status
      })
      this.$refs.mTable.$refs.myTable.clearSelection()
      this.selectValue = []
      // this.doSearch(this.baseFormModel)
    },
    // 1-禁用 0-启用
    async changeStatus(row) {
      if (row.status) {
        const { code } = await this.$httpService(this.$apiStore.workflow('enableTallyLogisticsConfig'), { id: row.id })
        if (code) return
        this.$message.success(this.$t('lang.gles.strategy.enableSuccess'))
      } else {
        const { code } = await this.$httpService(this.$apiStore.workflow('disableTallyLogisticsConfig'), { id: row.id })
        if (code) return
        this.$message.success(this.$t('lang.gles.strategy.disableSuccess'))
      }
      row.status = row.status ? 0 : 1
      // this.doSearch(this.baseFormModel)
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    // 更多查询
    handleMoreSearch(data) {
      // console.log(event)
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'queryTallyLogisticsConfig')
    },
    /**
     * 更多查询重置方法
     * @param {*} data
     */
    handleReset(data) {
      this.formModel = data
      // 更新级联下拉选项
      this.getClassifyByFirstClassify()
      this.getMaterialCodeList()
      this.getFactoryPositionList()
      this.getGoodsPositionList()
    },
    // 删除
    async delItem({ row }) {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('deleteTallyLogisticsConfig'),
        { id: row.id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      if (this.tableData.length < 2) this.resetPageData()
      this.doSearch(this.baseFormModel)
    }
  }
}
</script>
