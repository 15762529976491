import mapVerify from '@/utils/formValidate'
import { t } from '@/libs_sz/locale'
import myTransform from '@/libs_sz/utils/transform'

const getFormatter = (data, value) => {
  const datas = [{ label: t('lang.gles.common.all'), value: 0 }].concat(data)
  const getValue = myTransform.arrToObject(datas, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}
const formatOptions = data => { return [{ label: t('lang.gles.common.all'), value: 0 }].concat(data) }

export const getMoreQueryFormData = (that, row) => {
  const moreQueryData = [
    // 配置名称
    {
      name: 'configName',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.workflow.configName'),
      clearable: true
    },
    // 来源库区
    {
      name: 'sourceWarehouseAreaId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.sourceWarehouseArea'),
      data: formatOptions(that.warehouseAreaList),
      clearable: true,
      mchange: that.handleWarehouseAreaChange
    },
    // 来源库位
    {
      name: 'sourceFactoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      clearable: true,
      labelText: 'factoryPositionCode',
      label: that.$t('lang.gles.workflow.sourceFactoryPosition'),
      // data: formatOptions(that.sFactoryPositionList),
      mchange: that.handleFactoryPositionChange
    },
    // 来源货位
    {
      name: 'sourceGoodsPositionId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.sourceGoodsPosition'),
      data: formatOptions(that.sGoodsPositionList),
      clearable: true
    },
    // 目标库区
    {
      name: 'targetWarehouseAreaId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.targetWarehouseArea'),
      data: formatOptions(that.warehouseAreaList),
      clearable: true,
      mchange: that.onWarehouseAreaChange
    },
    // 目标库位
    {
      name: 'targetFactoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      clearable: true,
      labelText: 'factoryPositionCode',
      label: that.$t('lang.gles.workflow.targetFactoryPosition'),
      mchange: that.onFactoryPositionChange
    },
    // 目标货位
    {
      name: 'targetGoodsPositionId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.targetGoodsPosition'),
      data: formatOptions(that.tGoodsPositionList),
      clearable: true
    },
    // 移动容器类型
    {
      name: 'moveContainerTypeId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.moveContainerType'),
      clearable: true,
      data: formatOptions(that.moveContainerTypeList || [])
    },
    // 周转容器类型
    {
      name: 'turnoverContainerTypeId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      clearable: true,
      data: formatOptions(that.turnoverContainerTypeList || [])
    },
    // 物料一级分类
    {
      name: 'materialFirstClassifyId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      clearable: true,
      data: formatOptions(that.materialClassifyFirstList || []),
      mchange: that.onClassifyFirstChange
    },
    // 物料二级分类
    {
      name: 'materialSecondClassifyId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      clearable: true,
      data: formatOptions(that.classifySecondList || []),
      mchange: that.onClassifySecondChange
    },
    // 物料编码
    {
      name: 'materialId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      data: formatOptions(that.materialCodeList || [])
    },
    // status
    {
      name: 'status',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.status'),
      clearable: true,
      data: that.statusFlagList || []
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 配置名称
    {
      prop: 'configName',
      label: that.$t('lang.gles.workflow.configName'),
      isShow: true
    },
    // 来源库区
    {
      prop: 'sourceWarehouseAreaId',
      label: that.$t('lang.gles.workflow.sourceWarehouseArea'),
      formatter(row, column) {
        return getFormatter(that.warehouseAreaList, row[column])
      },
      isShow: true
    },
    // 来源库位
    {
      prop: 'sourceFactoryPositionId',
      label: that.$t('lang.gles.workflow.sourceFactoryPosition'),
      formatter(row, column) {
        return getFormatter(that.factoryPositionlist, row[column])
      },
      isShow: true
    },
    // 来源货位
    {
      prop: 'sourceGoodsPositionId',
      label: that.$t('lang.gles.workflow.sourceGoodsPosition'),
      formatter(row, column) {
        return getFormatter(that.goodsPositionlist, row[column])
      },
      isShow: true
    },
    // 目标库区
    {
      prop: 'targetWarehouseAreaId',
      label: that.$t('lang.gles.workflow.targetWarehouseArea'),
      formatter(row, column) {
        return getFormatter(that.warehouseAreaList || [], row[column])
      },
      isShow: true
    },
    // 目标库位
    {
      prop: 'targetFactoryPositionId',
      label: that.$t('lang.gles.workflow.targetFactoryPosition'),
      formatter(row, column) {
        return getFormatter(that.factoryPositionlist, row[column])
      },
      isShow: true
    },
    // 目标货位
    {
      prop: 'targetGoodsPositionId',
      label: that.$t('lang.gles.workflow.targetGoodsPosition'),
      formatter(row, column) {
        return getFormatter(that.goodsPositionlist, row[column])
      },
      isShow: true
    },
    // 移动容器类型
    {
      prop: 'moveContainerTypeId',
      label: that.$t('lang.gles.common.moveContainerType'),
      formatter(row, column) {
        return getFormatter(that.moveContainerTypeList, row[column])
      },
      isShow: true
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      formatter(row, column) {
        return getFormatter(that.turnoverContainerTypeList, row[column])
      },
      isShow: true
    },
    // 物料一级分类
    {
      prop: 'materialFirstClassifyId',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      formatter(row, column) {
        return getFormatter(that.materialClassifyFirstList, row[column])
      },
      isShow: true
    },
    // 物料二级分类
    {
      prop: 'materialSecondClassifyId',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      formatter(row, column) {
        return getFormatter(that.materialClassifySecondList || [], row[column])
      },
      isShow: true
    },
    // 物料编码
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialCode'),
      formatter(row, column) {
        return getFormatter(that.materialCodelist || [], row[column])
      },
      isShow: true
    },
    // 状态
    {
      prop: 'status',
      label: that.$t('lang.gles.common.statusFlag'),
      slotName: 'enableStatus',
      width: '90px',
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const editBaseInfoFormData = [
    // 配置名称
    {
      name: 'configName',
      value: row.configName || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.workflow.configName'),
      placeholder: that.$t('lang.gles.common.inputLen200'),
      rules: mapVerify(['required', 'inputLen200']),
      clearable: true
    }
  ]
  return editBaseInfoFormData
}
// 匹配条件
export const getMatchConditionFormData = (that, options, row) => {
  const matchConditionFormData = [
    // 来源库区
    {
      name: 'sourceWarehouseAreaId',
      value: row.sourceWarehouseAreaId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.sourceWarehouseArea'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.warehouseAreaList || []),
      mchange: that.handleWarehouseAreaChange
    },
    // 来源库位
    {
      name: 'sourceFactoryPositionId',
      value: row.sourceFactoryPositionId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.sourceFactoryPosition'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.sFactoryPositionList || []),
      mchange: that.handleFactoryPositionChange
    },
    // 来源货位
    {
      name: 'sourceGoodsPositionId',
      value: row.sourceGoodsPositionId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.sourceGoodsPosition'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.sGoodsPositionList || [])
    },
    // 目标库区
    {
      name: 'targetWarehouseAreaId',
      value: row.targetWarehouseAreaId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.targetWarehouseArea'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.warehouseAreaList || []),
      mchange: that.onWarehouseAreaChange
    },
    // 目标库位
    {
      name: 'targetFactoryPositionId',
      value: row.targetFactoryPositionId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.targetFactoryPosition'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.tFactoryPositionList || []),
      mchange: that.onFactoryPositionChange
    },
    // 目标货位
    {
      name: 'targetGoodsPositionId',
      value: row.targetGoodsPositionId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.targetGoodsPosition'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.tGoodsPositionList || [])
    },
    // 移动容器类型
    {
      name: 'moveContainerTypeId',
      value: row.moveContainerTypeId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.moveContainerType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.moveContainerTypeList || [])
    },
    // 周转容器类型
    {
      name: 'turnoverContainerTypeId',
      value: row.turnoverContainerTypeId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.turnoverContainerTypeList || [])
    },
    // 物料一级分类
    {
      name: 'materialFirstClassifyId',
      value: row.materialFirstClassifyId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.materialClassifyFirstList || []),
      mchange: that.onClassifyFirstChange
    },
    // 物料二级分类
    {
      name: 'materialSecondClassifyId',
      value: row.materialSecondClassifyId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.classifySecondList || []),
      mchange: that.onClassifySecondChange
    },
    // 物料编码
    {
      name: 'materialId',
      value: row.materialId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.materialCodeList || [])
    }
  ]
  return matchConditionFormData
}

// 机器人配置
export const getRobotConfigFormData = (that, options, row = {}) => {
  // console.log(row, 'row')
  const configFormData = [
    // 机器人系列
    {
      name: 'robotType',
      value: row.robotType || '',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotSeries'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.robotTypeList || [],
      mchange(val, item, cb) {
        that.getWorkTemplateByRobotType(val ? { type: val } : '')
        cb({ workTemplateId: '' })
      }
    },
    // 机器人型号
    {
      name: 'robotModel',
      value: row.robotModel || [],
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotModel'),
      rules: mapVerify(['required']),
      clearable: true,
      multiple: true,
      data: options.robotModelList || []
    },
    // 作业模板
    {
      name: 'workTemplateId',
      value: row.workTemplateId || '',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.workflow.workTemplate'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.workTemplateList || []
    }
  ]
  return configFormData
}

