import myTransform from '@/libs_sz/utils/transform'
export default {
  data() {
    return {
      sFactoryPositionList: [], // 来源库位
      tFactoryPositionList: [], // 目标库位
      sGoodsPositionList: [], // 来源货位
      tGoodsPositionList: [], // 目标货位
      classifySecondList: [],
      materialCodeList: [],
      workTemplateList: []
    }
  },

  methods: {
    initSelectList() {
      this.getClassifyByFirstClassify()
      this.getMaterialCodeList()
      // this.getWorkTemplateByRobotType()
      this.getFactoryPositionList()
      this.getGoodsPositionList()
    },
    initEditSelectList(rowDetail) {
      // 来源及目标
      const sourceFactoryPositionParams = rowDetail.sourceWarehouseAreaId ? { warehouseAreaId: rowDetail.sourceWarehouseAreaId } : {}
      const sourceGoodsPositionParams = rowDetail.sourceFactoryPositionId ? { factoryPositionId: rowDetail.sourceFactoryPositionId, ...sourceFactoryPositionParams } : { ...sourceFactoryPositionParams }
      this.getFactoryPositionList(sourceFactoryPositionParams, 'source')
      this.getGoodsPositionList(sourceGoodsPositionParams, 'source')
      const targetFactoryPositionParams = rowDetail.targetWarehouseAreaId ? { warehouseAreaId: rowDetail.targetWarehouseAreaId } : {}
      const targetGoodsPositionParams = rowDetail.targetFactoryPositionId ? { factoryPositionId: rowDetail.targetFactoryPositionId, ...targetFactoryPositionParams } : { ...targetFactoryPositionParams }
      this.getFactoryPositionList(targetFactoryPositionParams, 'target')
      this.getGoodsPositionList(targetGoodsPositionParams, 'target')
      const classifyParams = rowDetail.materialFirstClassify ? { parentId: rowDetail.materialFirstClassify } : {}
      this.getClassifyByFirstClassify(classifyParams)
      const materialParams = {}
      rowDetail.materialFirstClassify ? materialParams.classifyFirst = rowDetail.materialFirstClassify : ''
      rowDetail.materialSecondClassify ? materialParams.classifySecond = rowDetail.materialSecondClassify : ''
      this.getMaterialCodeList(materialParams)
      this.getWorkTemplateByRobotType({ type: rowDetail.robotType })
    },
    /**
     * 获取库位列表
     */
    getFactoryPositionList(params, type) {
      this.getSelectOptions('getFactoryPositionDictList', params).then((list) => {
        if (type === 'source') {
          this.sFactoryPositionList = list
        } else if (type === 'target') {
          this.tFactoryPositionList = list
        } else {
          this.sFactoryPositionList = list
          this.tFactoryPositionList = list
        }
      })
    },
    /**
     * 获取货位列表
     */
    getGoodsPositionList(params, type) {
      this.$httpService(this.$apiStore.base('getGoodsPositionListByType'), params).then(({ data }) => {
        const list = data.map(item => { return { label: item.code, value: item.id } })
        if (type === 'source') {
          this.sGoodsPositionList = list
        } else if (type === 'target') {
          this.tGoodsPositionList = list
        } else {
          this.sGoodsPositionList = list
          this.tGoodsPositionList = list
        }
      })
    },
    /**
     * 获取数据列表
     * @param {*} apiName 接口名
     * @param {*} params 请求参数
     * @returns 选项列表
     */
    getSelectOptions(apiName, params) {
      const that = this
      return new Promise((resolve, reject) => {
        this.$httpService(this.$apiStore.base(apiName), params)
          .then(({ data, code }) => {
            if (code !== 0) {
              reject()
              return
            }
            const recordList = data || []
            const list = recordList.map((item) => {
              if (item.containerTypeCode) {
                return {
                  label: that.$t(item.containerTypeName),
                  value: item.id,
                  containerTypeCode: item.containerTypeCode,
                  containerTypePattern: item.containerTypePattern,
                  containerTypePatternType: item.containerTypePatternType
                }
              }
              if (item.factoryPositionCode) {
                return {
                  label: item.factoryPositionName,
                  value: item.id,
                  factoryPositionCode: item.factoryPositionCode,
                  factoryPositionType: item.factoryPositionType,
                  freezeFlag: item.freezeFlag
                }
              }
              return {
                label: that.$t(item.name || item.code),
                value: item.id
              }
            })
            resolve(list)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取周转容器类型
    async getTurnoverContainerTypeList() {
      const params = {
        containerTypePatternList: ['containerPatternBarrel', 'containerPatternCrate']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerType'), params)
      if (code) return
      this.turnoverContainerTypeList = myTransform.arrToOptions(data, 'containerTypeName', 'containerTypeId')
    },
    // 获取移动容器类型
    async getMoveContainerTypeList() {
      const params = {
        containerTypePatternList: ['containerPatternShelf', 'containerPatternTray', 'containerPatternRobotComponents']
      }
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerType'), params)
      if (code) return
      this.moveContainerTypeList = myTransform.arrToOptions(data, 'containerTypeName', 'containerTypeId')
    },
    // 根据一级分类级联二级分类
    async getClassifyByFirstClassify(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryClasifyByCodeOrName'), { ...params, level: 2 })
      if (code) return
      this.classifySecondList = myTransform.arrToOptions(data, 'classifyName', 'id')
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryMaterialOption'), params)
      if (code) return
      this.materialCodeList = data.map(item => { return { label: item.materialCode, value: item.id } })
    },
    /**
     * 一级级联二级和物料编码
     */
    onClassifyFirstChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.materialSecondClassifyId = 0
      formData.materialId = 0
      const params = formData.materialFirstClassifyId ? { parentId: formData.materialFirstClassifyId } : {}
      const materialParams = formData.materialFirstClassifyId ? { classifyFirst: formData.materialFirstClassifyId } : {}
      this.getClassifyByFirstClassify(params)
      this.getMaterialCodeList(materialParams)
    },
    /**
     *二级级联--物料编码
    */
    onClassifySecondChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.materialId = 0
      const materialParams = {}
      formData.materialFirstClassifyId ? materialParams.classifyFirst = formData.materialFirstClassifyId : ''
      formData.materialSecondClassifyId ? materialParams.classifySecond = formData.materialSecondClassifyId : ''
      this.getMaterialCodeList(materialParams)
    },
    /**
     * 来源库区 change
     * 库区--库位--货位
     */
    handleWarehouseAreaChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.sourceFactoryPositionId = 0
      formData.sourceGoodsPositionId = 0
      const params = formData.sourceWarehouseAreaId ? { warehouseAreaId: formData.sourceWarehouseAreaId } : {}
      this.getFactoryPositionList(params, 'source')
      this.getGoodsPositionList(params, 'source')
    },
    /**
     * 来源库位 change
     * 库位-货位
     */
    handleFactoryPositionChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.sourceGoodsPositionId = 0
      const params = formData.sourceFactoryPositionId ? { factoryPositionId: formData.sourceFactoryPositionId } : {}
      this.getGoodsPositionList(params, 'source')
    },
    /**
     * 目标库区 change
     * 库区--库位--货位
     */
    onWarehouseAreaChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.targetFactoryPositionId = 0
      formData.targetGoodsPositionId = 0
      const params = formData.targetWarehouseAreaId ? { warehouseAreaId: formData.targetWarehouseAreaId } : {}
      this.getFactoryPositionList(params, 'target')
      this.getGoodsPositionList(params, 'target')
    },
    /**
     * 目标库位 change
     * 库位-货位
     */
    onFactoryPositionChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.targetGoodsPositionId = 0
      const params = formData.targetFactoryPositionId ? { factoryPositionId: formData.targetFactoryPositionId } : {}
      this.getGoodsPositionList(params, 'target')
    }
  }
}
