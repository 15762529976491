<template>
  <div class="basic-data-add">
    <el-card class="add-card">
      <div class="form_title b mb15 mt15">
        {{ title }}{{ $t("lang.gles.workflow.tallyLogisticsConfig") }}
      </div>
      <div class="basicInfo">
        <el-divider content-position="left">
          {{ $t("lang.gles.common.basicInfo") }}
        </el-divider>
        <m-form
          ref="myForm"
          :form-data="basicFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        >
          <template #inputCodeTipIcon>
            <el-tooltip
              popper-class="workshop_code_tip-icon"
              effect="dark"
              :content="$t(autoGeneCodeTip)"
              placement="right"
            >
              <i class="el-icon-tip el-icon-question tip" />
            </el-tooltip>
          </template>
        </m-form>
      </div>
      <!-- 匹配条件 -->
      <div class="targetRange">
        <el-divider content-position="left">
          {{ $t("lang.gles.strategy.matchCondition") }}
        </el-divider>
        <m-form
          ref="myMatchForm"
          :form-data="matchConditionFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </div>
      <!-- 机器人配置 -->
      <div class="appStrategy">
        <el-divider content-position="left">
          {{ $t("lang.gles.workflow.robotConfig") }}
        </el-divider>
        <m-form
          ref="myRobotConfig"
          :form-data="robotConfigFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </div>
      <div class="footer-btns">
        <el-button @click="onCancel">
          {{ $t("lang.gles.common.cancel") }}
        </el-button>
        <el-button v-debounce="onSubmit" type="primary">
          {{ $t("lang.gles.common.confirm") }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import addTallyConfigMixins from '@/mixins/addTallyConfigMixins.js'
import { getEditBaseFormData, getMatchConditionFormData, getRobotConfigFormData } from '../data'
import myTransform from '@/libs_sz/utils/transform'
export default {
  name: 'AddTallyConfig',
  components: {
    MForm
  },
  mixins: [addMixins, addTallyConfigMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => { }
    },
    options: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      title: this.mode === 'add' ? this.$t('lang.gles.common.addNew') : this.mode === 'edit' ? this.$t('lang.gles.common.edit') : this.$t('lang.gles.common.copy'),
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      }
    }
  },
  computed: {
    basicFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    matchConditionFormData() {
      const row = this.rowDetail || {}
      return getMatchConditionFormData(this, this.options, { ...row })
    },
    robotConfigFormData() {
      const row = this.rowDetail || {}
      if (this.mode === 'edit' || this.mode === 'copy') {
        row.robotModel = Array.isArray(row.robotModel) ? row.robotModel : row.robotModel.split(',')
      }
      return getRobotConfigFormData(this, this.options, { ...row })
    }
  },
  created() {
    if (this.mode === 'edit' || this.mode === 'copy') {
      const rowDetail = this.rowDetail || {}
      this.initEditSelectList(rowDetail)
      return
    }
    this.initSelectList()
  },
  methods: {
    // 根据机器人类型查询作业模板
    async getWorkTemplateByRobotType(params) {
      const { data = [], code } = await this.$httpService(this.$apiStore.workflow('getWorkTemplateByRobotType'), params)
      if (code) return
      this.workTemplateList = myTransform.arrToOptions(data, 'templateName', 'id')
      // this.workTemplateList = [{ value: 1, label: 'lou' }]
    },
    // edit或copy 下获取详情
    async queryTallyLogisticsConfigDetail() {
      const { code, data } = await this.$httpService(this.$apiStore.workflow('queryTallyLogisticsConfigDetail'), { id: this.rowDetail.id })
      if (code) return
      // 回显
      console.log(data, 'data09000000')
    },
    onSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        this.$refs.myMatchForm.getValidateFormModel().then(async(matchFormModel) => {
          this.$refs.myRobotConfig.getValidateFormModel().then(async(goodsPositionFormModel) => {
            const params = { ...this.rowDetail, ...model, ...matchFormModel, ...goodsPositionFormModel }
            params.robotModel = Array.isArray(params.robotModel) ? params.robotModel.join(',') : params.robotModel
            console.log(params, 'params')
            if (this.mode === 'copy') {
              delete params.id
              delete params.updateUser
            }
            if (this.mode === 'edit') delete params.updateUser
            this.mode === 'edit' ? await this.update(params) : await this.insert(params)
          })
        })
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(this.$apiStore.workflow('addTallyLogisticsConfig'), params)
      if (code) return
      this.mode === 'add' ? this.$message.success(this.$t('lang.gles.common.addSuccessfully')) : this.$message.success(this.$t('lang.gles.strategy.copySuccessfully'))
      this.onCancel()
    },
    async update(params) {
      const { code } = await this.$httpService(this.$apiStore.workflow('updateTallyLogisticsConfig'), params)
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'tallyConfigList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_title {
  border-left: 4px solid #409eff;
  height: 18px;
  line-height: 20px;
  padding-left: 8px;
  font-size: 16px;
}
.tip {
  display: inline-block;
  position: absolute;
  top: 6px;
  right: -24px;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
