<template>
  <component
    :is="currentCom"
    :mode="mode"
    :row-detail.sync="row"
    :options="options"
    @updateCom="updateCom"
  />
</template>
<script>
import tallyConfigList from './components/list.vue'
import tallyConfigAdd from './components/add.vue'
export default {
  name: 'TallyConfig',
  components: {
    tallyConfigList,
    tallyConfigAdd
  },
  data() {
    return {
      currentCom: 'tallyConfigList',
      mode: '',
      row: {},
      options: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row, options }) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
      this.options = options
    }
  }
}
</script>
<style lang="scss" scoped></style>
